// Angular
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MaterialModule } from '@app/shared/app-angular-material.module';
import { MediaService } from '@service/services';
// Core module
import { SharedCoreModule } from '@app/core/core.module';
import { ScrollbarModule } from '@app/shared/scrollbar/scrollbar.module';
// CRUD Partials
import {
	ActionNotificationComponent,
	AlertComponent,
	DeleteEntityDialogComponent,
	FetchEntityDialogComponent,
	UpdateStatusDialogComponent
} from './content/crud';
import { InfoDialogComponent } from './content/crud/info-dialog/info-dialog.component';


@NgModule({
	declarations: [
		ActionNotificationComponent,
		DeleteEntityDialogComponent,
		FetchEntityDialogComponent,
		UpdateStatusDialogComponent,
		AlertComponent,


		InfoDialogComponent,

	],
	exports: [
		ActionNotificationComponent,
		DeleteEntityDialogComponent,
		FetchEntityDialogComponent,
		UpdateStatusDialogComponent,
		AlertComponent,
		InfoDialogComponent,
		ScrollbarModule,
	],
	imports: [
		CommonModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		MaterialModule,
		SharedCoreModule,
		ScrollbarModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		}),
	],
	entryComponents: [
		ActionNotificationComponent,
		DeleteEntityDialogComponent,
		UpdateStatusDialogComponent,
		InfoDialogComponent
	],
	providers: [
		MediaService
	]
})
export class PartialsModule {
}
export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http);
}
