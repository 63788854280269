<div class="default-toaster__content" fxLayout="row" fxLayoutAlign="center">
  <!-- <mat-icon [svgIcon]="iconName" [ngClass]="data.status ? data.status : ''"></mat-icon> -->

  <p [innerHTML]="data.message" fxFlex="60%"></p>

  <div class="default-toaster__content__action" fxLayout="column" fxLayoutAlign="center center" fxFlex="30%">
    <button *ngIf="data.showUndoButton" type="button" mat-button (click)="onDismissWithAction()">
      {{'common.undo'|translate}}
    </button>

    <button *ngIf="data.showCloseButton" mat-button (click)="onDismiss()">
      {{'common.close'|translate}}
    </button>
  </div>
</div>
