import { NgModule } from '@angular/core';
import { SharedService } from '@service/services';
import { SharedModule } from '@app/shared/shared.module';
import { LayoutComponent } from './layout.component';
@NgModule({
  imports: [
    SharedModule,
  ],
  providers: [SharedService],
  declarations: [LayoutComponent]
})
export class LayoutModule {
}
