import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LayoutComponent } from "./layout/layout.component";
import { AuthGuard } from "./core/auth";

const routes: Routes = [
  {
    path: "",
    component: LayoutComponent,
    children: [
      {
        path: "",
        loadChildren: () => import("./pages/template1/splash/splash.module").then((x) => x.SplashModule),
        canActivate: [AuthGuard],
      },
      {
        path: "home",
        loadChildren: () => import("./pages/template1/home/home.module").then((x) => x.HomeModule),
        canActivate: [AuthGuard],
      },
      {
        path: "orders",
        loadChildren: () => import("./pages/template1/orders/orders.module").then((x) => x.OrdersModule),
        canActivate: [AuthGuard],
      },
      {
        path: "queue",
        loadChildren: "./pages/template1/queue/queue.module#QueueModule",
      },
    ],
  },
  {
    path: "theme1",
    component: LayoutComponent,
    children: [
      {
        path: "",
        loadChildren: () => import("./pages/template1/splash/splash.module").then((x) => x.SplashModule),
        canActivate: [AuthGuard],
      },
      {
        path: "home",
        loadChildren: () => import("./pages/template1/home/home.module").then((x) => x.HomeModule),
        canActivate: [AuthGuard],
      },
      {
        path: "orders",
        loadChildren: () => import("./pages/template1/orders/orders.module").then((x) => x.OrdersModule),
        canActivate: [AuthGuard],
      },
      {
        path: "queue",
        loadChildren: "./pages/template1/queue/queue.module#QueueModule",
      },
    ],
  },
  {
    path: "theme2",
    component: LayoutComponent,
    children: [
      {
        path: "",
        loadChildren: () => import("./pages/template2/splash/splash.module").then((x) => x.SplashModule),
        canActivate: [AuthGuard],
      },
      {
        path: "home",
        loadChildren: () => import("./pages/template2/home/home.module").then((x) => x.HomeModule),
        canActivate: [AuthGuard],
      },
      {
        path: "orders",
        loadChildren: () => import("./pages/template2/orders/orders.module").then((x) => x.OrdersModule),
        canActivate: [AuthGuard],
      },
      {
        path: "queue",
        loadChildren: "./pages/template2/queue/queue.module#QueueModule",
      },
    ],
  },
  {
    path: "theme3",
    component: LayoutComponent,
    children: [
      {
        path: "",
        loadChildren: () => import("./pages/template3/template3.module").then((x) => x.Template3Module),
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: "theme4",
    component: LayoutComponent,
    children: [
      {
        path: "",
        loadChildren: () => import("./pages/template4/template4.module").then((x) => x.Template4Module),
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: "theme5",
    component: LayoutComponent,
    loadChildren: () => import("./pages/template5/template5.module").then((x) => x.Template5Module),
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
