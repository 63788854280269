// Anglar
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FirstLetterPipe } from './_base/layout';

@NgModule({
	imports: [CommonModule],

	declarations: [FirstLetterPipe],

	exports: [FirstLetterPipe],

	providers: []
})
export class SharedCoreModule {
}
