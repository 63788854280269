import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'kiosk-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class LayoutComponent implements OnInit {
  collapsed = true;
  isLoggedIn = false;

  chatDrawerOpen = false;
  ispotrait = true;

  constructor(
    private router: Router,
  ) {
    // do not make potrait layout in orders and queue
    if ((this.router.url.indexOf("/orders") != -1) || (this.router.url.indexOf("/queue") != -1) || (this.router.url.indexOf("/counter") != -1)) {
      this.ispotrait = false;
    }
  }

  ngOnInit() { }
}
