import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor() { }

  public classObservable = new Subject<any>();
  emitClassChange(val) {
    this.classObservable.next(val);
  }
}
