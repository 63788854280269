import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { Coupon } from "@app/_models/product";

@Injectable({
  providedIn: "root",
})
export class ProductService {
  constructor(private http: HttpClient) {}

  public categoryObservable = new Subject<any>();
  public productObservable = new Subject<any>();
  public productCloseObservable = new Subject<any>();
  public productOrderObservable = new Subject<any>();
  public navObservable = new Subject<any>();
  public filterObservable = new Subject<any>();
  public couponObservable = new BehaviorSubject<Coupon>({
    applied: false,
    data: { id: null, discount: null, code: null, status: null },
    message: null,
  });
  public currentView = new BehaviorSubject<String>(null);
  private discountAmount = new BehaviorSubject<number>(0);

  emitCategoryChange(val) {
    this.categoryObservable.next(val);
  }

  emitProductChange(val) {
    this.productObservable.next(val);
  }

  emitProductCloseChange(val) {
    this.productCloseObservable.next(val);
  }

  emitProductOrderChange(val) {
    this.productOrderObservable.next(val);
  }

  emitNavChange(val) {
    this.navObservable.next(val);
  }

  emitFilterChange(val) {
    this.filterObservable.next(val);
  }

  set currentViewSub(value: string) {
    this.currentView.next(value);
  }

  getAll(filter = "") {
    return this.http.get<any>(`${environment.apiUrl}/products` + filter);
  }

  getOrganizationProducts(filter = "") {
    return this.http.get<any>(`${environment.cmsApiUrl}/api/products` + filter);
  }

  order(data) {
    return this.http.post<any>(`${environment.apiUrl}/orders`, data);
  }

  organizationOrder(data, organization, resturant) {
    return this.http.post<any>(
      `${environment.cmsApiUrl}/api/orders?organization=${organization}&resturant=${resturant}`,
      data
    );
  }

  organizationPayAndOrder(data, organization, resturant) {
    return this.http.post<any>(
      `${environment.cmsApiUrl}/api/pay-and-order?organization=${organization}&resturant=${resturant}`,
      data
    );
  }

  organizationKioskOrder(data, organization, resturant) {
    return this.http.post<any>(
      `${environment.cmsApiUrl}/api/kiosk-order?organization=${organization}&resturant=${resturant}`,
      data
    );
  }

  organizationMorePayment(orderId, data, organization, resturant) {
    return this.http.put<any>(
      `${environment.cmsApiUrl}/api/add-more-order-payment/${orderId}?organization=${organization}&resturant=${resturant}`,
      data
    );
  }

  organizationRefund(data, organization, resturant) {
    return this.http.post<any>(
      `${environment.cmsApiUrl}/api/kiosk-refund?organization=${organization}&resturant=${resturant}`,
      data
    );
  }

  getReceipt(orderId, organization) {
    return this.http.get<Blob>(`${environment.cmsApiUrl}/api/orders/receipt/${orderId}?organization=${organization}`, {
      observe: "response",
      responseType: "blob" as "json",
    });
  }

  getOrders(filter = "") {
    return this.http.get<any>(`${environment.apiUrl}/orders` + filter);
  }

  getOrganizationOrders(filter = "") {
    return this.http.get<any>(`${environment.cmsApiUrl}/api/orders` + filter);
  }

  getOrganizationCounterOrders(filter = "") {
    return this.http.get<any>(`${environment.cmsApiUrl}/api/app-based-order` + filter);
  }

  updateOrder(order) {
    return this.http.patch<any>(`${environment.apiUrl}/orders`, order);
  }

  updateOrganizationOrder(order, organization) {
    return this.http.patch<any>(
      `${environment.cmsApiUrl}/api/orders/${order.orderId}?organization=${organization}`,
      order
    );
  }

  getOrganizationQueue(filter = "") {
    return this.http.get<any>(`${environment.cmsApiUrl}/api/queue` + filter);
  }

  getOrganizationSettings(organization, resturant = "", screen = "") {
    return this.http.get<any>(
      `${environment.cmsApiUrl}/api/settings?organization=${organization}&resturant=${resturant}&screen=${screen}`
    );
  }

  savePrinterSettings(data, organization) {
    return this.http.post<any>(`${environment.cmsApiUrl}/api/savePrinterSettings?organization=${organization}`, data);
  }

  payment(data, organization) {
    return this.http.post<any>(`${environment.cmsApiUrl}/api/payment?organization=${organization}`, data);
  }

  checkTerminalStatus(terminalId, organization, resturant) {
    return this.http.get<any>(
      `${environment.cmsApiUrl}/api/checkTerminal/${terminalId}?organization=${organization}&resturant=${resturant}`
    );
  }

  clearTerminal(terminalId, organization, resturant) {
    return this.http.get<any>(
      `${environment.cmsApiUrl}/api/clearTerminal/${terminalId}?organization=${organization}&resturant=${resturant}`
    );
  }

  getCountryList(organization) {
    return this.http.get<any>(`${environment.cmsApiUrl}/api/countries?organization=${organization}`);
  }

  print(endpoint) {
    return this.http.get<any>(endpoint);
  }

  requestAgeVerification(data, organization) {
    return this.http.post<any>(
      `${environment.cmsApiUrl}/api/request-age-verification?organization=${organization}`,
      data
    );
  }

  confirmAgeVerification(data, organization) {
    return this.http.post<any>(
      `${environment.cmsApiUrl}/api/confirm-age-verification?organization=${organization}`,
      data
    );
  }

  confirmOrderPayment(orderId, organization, resturant, provider) {
    const data = {
      id: orderId,
      provider: provider,
    };
    return this.http.post<any>(
      `${environment.cmsApiUrl}/api/confirm-payment-order?organization=${organization}&resturant=${resturant}`,
      data
    );
  }

  cancelOrder(orderId, organization, resturant) {
    const data = {
      id: orderId,
    };
    return this.http.post<any>(
      `${environment.cmsApiUrl}/api/cancel-order-payment?organization=${organization}&resturant=${resturant}`,
      data
    );
  }

  getMealCategories(categories: Array<any>) {
    // let allowedCategories=["burger","burgers","side","sides","drink","drinks"];
    let mealCategories = [];
    categories.forEach((category) => {
      // const enName=category?.name?.en?.toLowerCase();
      // if(allowedCategories.indexOf(enName) !== -1){
      //   mealCategories.push(category);
      // }
      if (category.isMeal) {
        mealCategories.push(category);
      }
    });
    return mealCategories;
  }

  getMealIds(categories: Array<any>) {
    // let allowedCategories=["burger","burgers"];
    let mealCategoryIds = [];
    categories.forEach((category) => {
      // const enName=category?.name?.en?.toLowerCase();
      // if(allowedCategories.indexOf(enName) !== -1){
      //   mealCategoryIds.push(category.id);
      // }
      if (category.isMeal) {
        mealCategoryIds.push(category.id);
      }
    });
    return mealCategoryIds;
  }

  applyCoupon(code: string, restaurantId: string, organizationId: number) {
    return this.http
      .post<any>(
        `${environment.cmsApiUrl}/api/verify-discount-code?organization=${organizationId}&resturant=${restaurantId}`,
        {
          code,
          restaurantId,
        }
      )
      .subscribe(
        ({ data, success, message }) => {
          this.couponObservable.next({ applied: success, data: data, message: message });
        },
        ({ error }) => {
          this.couponObservable.next({
            applied: error.success,
            data: { code: null, id: null, discount: null, status: null },
            message: error.error?.code ? error.error.code[0] : error.message,
          });
        }
      );
  }

  getCouponApplied(): Observable<Coupon> {
    return this.couponObservable.asObservable();
  }

  resetCoupon() {
    this.couponObservable.next({
      applied: false,
      data: { id: null, discount: null, code: null, status: null },
      message: null,
    });
  }

  get currentViewValue(): Observable<String> {
    return this.currentView.asObservable();
  }

  set setDiscountAmount(amount: number) {
    this.discountAmount.next(amount);
  }

  get getDiscountAmount() {
    return this.discountAmount.asObservable();
  }
}
