export const environment = {
	apiUrl: 'https://demo.screenpro.cloud:3002',
	cmsApiUrl: 'https://api.kioskcloud.io',
	production: false,
	isMockEnabled: false, // You have to switch this, when your real back-end is done
	authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
	broadcastUrl: 'http://localhost:6001',
	currency: '€',
	pusherKey:'f1e322f3feee65a14333',
	pushserCluster:'eu',
	appVersion: require('../../package.json').version,
};