<mat-sidenav-container
  autosize
  class="h-full"
  [ngClass]="ispotrait ? 'potrait-mode' : ''"
>
  <mat-sidenav-content>
    <kiosk-scrollbar fxFlex="auto">
      <router-outlet></router-outlet>
    </kiosk-scrollbar>
  </mat-sidenav-content>
</mat-sidenav-container>
