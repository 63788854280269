import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, map, switchMap, take } from "rxjs/operators";
import { environment } from '@env/environment';
import { AuthService } from '../../../auth/_services/auth.service';
​
@Injectable()
export class InterceptService implements HttpInterceptor {
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
​
  constructor(private authService: AuthService) {}
​   
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  // intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    
    const token = localStorage.getItem('accessToken');
    const lang=localStorage.getItem('lang') || 'en';
    const organization=localStorage.getItem('organization');
    const apiKey=localStorage.getItem('apiKey');
​
    if (token) {
      request = this.addAccessToken(request,token);
    }
​
    if (!request.headers.has('Content-Type')) {
      request = request.clone({
        // setHeaders: {
        //   'Content-type': 'application/json'
        // }
      });
    }
​
    request = request.clone({
      headers: request.headers.set('Accept', 'application/json')
    });
    request = request.clone({
      headers: request.headers.set('Content-Language', lang)
    });
    
    return next.handle(request).pipe(map((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) { 
      }
      return event;
      }),
      catchError((error: HttpErrorResponse) => {
        
        if ((error && error.status === 401 || error.status === 403) && request.url!= `${environment.apiUrl}/api/login`) {
          // 401 errors are most likely going to be because we have an expired token that we need to refresh.
          if (this.refreshTokenInProgress) {
            // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
            // which means the new token is ready and we can retry the request again
            return this.refreshTokenSubject.pipe(filter(result => result !== null),take(1),
              switchMap(() => next.handle(this.addAccessToken(request,localStorage.getItem('accessToken'))))
            );
          }else{
            this.refreshTokenInProgress = true;
            // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
            this.refreshTokenSubject.next(null);
            
            return this.authService.login(organization,apiKey).pipe(
              switchMap((success: boolean) => {
                this.refreshTokenSubject.next(success);
                return next.handle(this.addAccessToken(request,localStorage.getItem('accessToken')));
              }),
              // When the call to refreshToken completes we reset the refreshTokenInProgress to false
              // for the next time the token needs to be refreshed
              finalize(() => (this.refreshTokenInProgress = false))
            );
          }
        }else{
          return throwError(error);
        }
      })
    );
  }
​
  private addAccessToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }
}
