<div mat-dialog-title>
  <button mat-icon-button mat-dialog-close class="btn-close ml-auto">
    <mat-icon svgIcon="">close</mat-icon>
  </button>
</div>
<mat-dialog-content class="text-center">
  <h4 class="" fxLayout="column" fxLayoutAlign="center center">
    <mat-icon *ngIf="statuses === 'success'" style="width: 7rem; height: auto; display: block; margin-top: -1rem;font-size: 7rem;">check
    </mat-icon>
  </h4>
  <p>{{data.messages}}</p>
  <button matRipple class="btn-default btn-default--primary" (click)="gotToCart()">{{'kiosk.goToCart' | translate}}</button>
</mat-dialog-content>
