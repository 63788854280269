import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
// language list
import { locale as enLang } from './core/_config/i18n/en';
import { TranslationService } from './core/_base/layout/services/translation.service';
import { icons } from './shared/icons/app-icons';

@Component({
  selector: 'kiosk-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(
    private translationService: TranslationService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer
  ) {
    // register translations
    this.translationService.loadTranslations(enLang);

    // mat icon generator
    icons.forEach(icon => {
      iconRegistry.addSvgIcon(
        icon.name,
        sanitizer.bypassSecurityTrustResourceUrl(icon.url)
      );
    });
  }
}
