import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '@env/environment';

@Injectable()
export class AuthService {
    public loginUserObservable = new Subject<any>();
    emitLoginUserChange(val) {
        this.loginUserObservable.next(val);
    }
    constructor(private http: HttpClient) { }
    // Authentication/Authorization
    login(organization: string, apiKey: string): Observable<any> {
        return this.http.post<any>(`${environment.cmsApiUrl}/api/login`, { organization, apiKey }).pipe(
            map(response => {
                localStorage.setItem('accessToken', response.data.token);
                return response;
            }),catchError((err)=>{
                // remove key from storage if unauthenticated
                localStorage.removeItem('apiKey')
                return err;
                
            })
        );
    }

    setLoginStatus(userId, isLoggedIn: boolean = true) {
        return this.http.put<any>(`${environment.apiUrl}/api/users/update/` + userId, { isLoggedIn: isLoggedIn });
    }

    updateProfile(user) {
        return this.http.post<any>(`${environment.apiUrl}/api/updatedGeneratedProfile/`, user);
    }

    refreshToken(): Observable<any> {
        return this.http.get<any>(environment.apiUrl + '/api/refreshToken').pipe(
            map(response => {
                localStorage.setItem('accessToken', response.data.refresh_token);
                let loggedInUser = JSON.parse(localStorage.getItem('currentUser'));
                if (loggedInUser) {
                    loggedInUser.ip = response.data.ip;// set updated ip address
                }
                return response;
            })
        );
    }

    getRoles(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/api/roles`);
    }

    getCountries(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/api/countries`);
    }

    resendCode(email): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.get<any>(`${environment.apiUrl}/api/resendCode?email=${email}`, { headers: httpHeaders });
    }

    initialVerification(data): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.post<any>(`${environment.apiUrl}/api/verifyUser`, data, { headers: httpHeaders });
    }

    /*
     * Submit forgot password request
     *
     * @param {string} email
     * @returns {Observable<any>}
     */
    public requestPassword(email: string): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.post<any>(`${environment.apiUrl}/api/forgotPassword`, { email: email }, { headers: httpHeaders });
    }

    resetPassword(data: any) {
        return this.http.post<any>(environment.apiUrl + "/api/resetPassword", data);
    }

    /*
     * Handle Http operation that failed.
     * Let the app continue.
   *
   * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: any) {
        return (error: any): Observable<any> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(result);
        };
    }
}
