// Angular
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'kiosk-update-status-dialog',
	templateUrl: './update-status-dialog.component.html'
})
export class UpdateStatusDialogComponent implements OnInit {
	selectedStatusForUpdate = new FormControl('');
	viewLoading = false;
	loadingAfterSubmit = false;
	statuses;
	constructor(
		private translate:TranslateService,
		public dialogRef: MatDialogRef<UpdateStatusDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any) { 
			this.translate.setDefaultLang(localStorage.getItem('lang') || 'en');
		}

	ngOnInit() {
		/* Server loading imitation. Remove this */
		this.viewLoading = true;
		setTimeout(() => {
			this.viewLoading = false;
		}, 2500);

		this.statuses = this.data.statuses;
	}

	onNoClick(): void {
		this.dialogRef.close();
	}

	gotToCart(){
		this.dialogRef.close("cart");
	}
}
