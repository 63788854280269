import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './app-angular-material.module';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MAT_TABS_CONFIG } from '@angular/material/tabs';
import { LayoutUtilsService, TypesUtilsService, InterceptService } from '../core/_base/crud';
import { CoreModule } from '@angular/flex-layout';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { PartialsModule } from '../pages/partials/partials.module';
import { ScrollbarModule } from './scrollbar/scrollbar.module';
import { SharedCoreModule } from '../core/core.module';
import { LocalTimePipe } from '../_pipes/localTimePipe';
import { LocalDateTimePipe } from '../_pipes/localDateTimePipe';
import { SafeHtmlPipe } from '../_pipes/safe-html.pipe';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DataTranslatePipe } from '../_pipes/data-translate.pipe';
import { FormatCurrencyPipe } from '../_pipes/format-currency.pipe';
import { AuthService } from '../core/auth';

@NgModule({
  imports: [
    CoreModule,
    HttpClientModule,
    CommonModule,
    SharedCoreModule,
    PartialsModule,
    RouterModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    ScrollbarModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  declarations: [LocalTimePipe, LocalDateTimePipe, SafeHtmlPipe, DataTranslatePipe, FormatCurrencyPipe],
  providers: [
    InterceptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        hasBackdrop: true,
        panelClass: '',
        height: 'auto',
        width: '900px'
      }
    },
    { provide: MAT_DATE_LOCALE, useValue: 'en' },
    { provide: MAT_TABS_CONFIG, useValue: { animationDuration: '0ms' } },
    TypesUtilsService,
    LayoutUtilsService,
    AuthService
  ],
  exports: [
    CoreModule,
    CommonModule,
    RouterModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    PartialsModule,
    SharedCoreModule,
    ScrollbarModule,
    LocalTimePipe,
    LocalDateTimePipe,
    SafeHtmlPipe,
    DataTranslatePipe,
    FormatCurrencyPipe,
    TranslateModule
  ]
})
export class SharedModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
