import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MediaService {

  constructor(private http: HttpClient) { }
  uploadMedia(data){
    return this.http.post<any>(`${environment.apiUrl}/api/medias`,data,{
      reportProgress: true,
      observe: 'events'
    });
  }
  removeMedia(filename){
    return this.http.delete<any>(`${environment.apiUrl}/api/medias/`+filename);
  }
}
