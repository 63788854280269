// Angular
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        // const user = localStorage.getItem('accessToken');
        // if (user) {
        //     console.log("authenticated");

        // } else {
        //     console.log("unauthenticated");
        // }
        return true;
    }
}
