import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { SrciptLoaderService } from './srcipt-loader.service';
declare var epson: any;

@Injectable({
  providedIn: 'root'
})
export class PrintService {

  constructor(private _scriptService: SrciptLoaderService) { 
    
  }
  public printerIP = '192.168.192.168';
  public printerPort = '8008';
  public printerDeviceId = 'local_printer';

  public printerStatus = new Subject<any>();
  emitPrinterStatusChange(val) {
    this.printerStatus.next(val);
  }

  order;
  productOrders=[];
  organizationData;
  taxes=[];
  totalTax=0;

  setPrinterConfig() {
    let config={
      printerIP:'192.168.192.168',
      printerPort:'8008',
      printerDeviceId:'local_printer'
    };
    
    let printerConfig=localStorage.getItem('printerConfig');
    if(printerConfig){
      config=JSON.parse(printerConfig);
    }
    this.printerIP = config.printerIP;
    this.printerPort = config.printerPort;
    this.printerDeviceId = config.printerDeviceId;
  }

  connect() {
    const _self=this;
    this._scriptService.load('epos').then(data => {
    const ePosDev = new epson.ePOSDevice();
      this.emitPrinterStatusChange("Connecting");
      ePosDev.connect(this.printerIP, this.printerPort, function(data){
        if (data == 'OK') {
          _self.emitPrinterStatusChange("Connected");
        }
        else if (data == 'SSL_CONNECT_OK') {
          _self.emitPrinterStatusChange("Connected");
        }
        else {
          _self.emitPrinterStatusChange("Disconnected");
        }
      });
    });
  }

  buildMessage() {
    //Create an ePOS-Print Builder object
    var builder = new epson.ePOSBuilder();
    //Create a print document
    builder.addTextLang('en')
    builder.addTextSmooth(true);
    builder.addTextFont(builder.FONT_A);
    builder.addTextSize(3, 3);
    builder.addText('Print Test! \n\n\n');
    builder.addCut(builder.CUT_FEED);
    //Acquire the print document
    var request = builder.toString();
    return request;
  }

  print(){
    
    this._scriptService.load('epos').then(data => {
      let ePosDev = new epson.ePOSDevice();
      this.setPrinterConfig();
      let request=this.buildMessage();
      //Set the end point address
      var address = `http://${this.printerIP}/cgi-bin/epos/service.cgi?devid=${this.printerDeviceId}&timeout=10000`;
      //Create an ePOS-Print object
      var epos = new epson.ePOSPrint(address);
      //Send the print document
      epos.send(request);

    }).catch(error => console.log(error));
    
  }

  formatCurrency(value) {
    value=parseFloat(value).toFixed(2);
    const settings=JSON.parse(localStorage.getItem('settings'));
    const currency=settings.currency || '$';
    const position=settings.symbolPosition || 'before';
    const decimalSeparator=settings.decimalSeparator || '.';

    value=value+"";// change to string
    value=value.replace(".", decimalSeparator);// transform decimal as per setting
    
    if(position == "before"){
      return currency+value;
    }else{
      return value+currency;
    }
    
  }

  prepareXMLReceipt(data){    
    let xml='<epos-print xmlns="http://www.epson-pos.com/schemas/2011/03/epos-print">\n'+
      `<text align="center" width="4" height="4">${this.order.queueNo}</text>\n`+
      `<feed unit="30"/>\n`+
      `<text>  &#10;</text>\n`+
      `<text align="center" width="1" height="1">${this.organizationData.name} &#10;</text>\n`+
      `<text align="center">${this.organizationData.address} ${this.organizationData.location} &#10;</text>\n`+
      `<text align="center">Business ID: ${this.organizationData.businessId} &#10;</text>\n`+
      `<text>  &#10;</text>\n`+
      `<text align="center" width="2" height="2">RECEIPT</text>\n`+
      `<text>  &#10;</text>\n`+
      `<feed line="1"/>\n`+
      `<text align="left" width="1" height="1">Order ID: ${this.order.id} &#10;</text>\n`+
      `<text>Date: ${this.order.createdAt} &#10;</text>\n`+
      `<text>  &#10;</text>\n`+
      `<feed line="1"/>\n`+
      `<text>${this.sliceString("QTY",3)}  ${this.sliceString("ITEM",25)} ${this.sliceString("TOTAL",9)}&#10;</text>\n`;

      this.productOrders.forEach(productOrder => {
        let sizeText='';
        if(productOrder.productOrderSizes){
          productOrder.productOrderSizes.forEach(productOrderSize => {
            if(productOrderSize.productSize && productOrderSize.productSize.size){
              sizeText=` -${productOrderSize.productSize.size.en}`;
            }
          });
        }
        xml+=`<text>${this.sliceString(productOrder.quantity,3)}  ${this.sliceString(productOrder.product.name.en+sizeText,25)} ${this.sliceString(this.formatCurrency(productOrder.priceWithVat*productOrder.quantity),9)}&#10;</text>\n`;
        if(productOrder.productOrderInstructions){
          productOrder.productOrderInstructions.forEach(instruction => {
            xml+=`<text>${this.sliceString("",3)}  ${this.sliceString(instruction.instruction,25)} ${this.sliceString("",9)}&#10;</text>\n`;
          });
        }
        
      });
      xml+=`<text>  &#10;</text>\n`+
      `<text>Total (incl VAT): ${this.formatCurrency(this.order.totalWithoutVat +this.order.vat)} &#10;</text>\n`+
      `<text>Card: ${this.formatCurrency(this.order.totalWithoutVat +this.order.vat)} &#10;</text>\n`+
      `<text>  &#10;</text>\n`+
      `<text>Total TAX: ${this.formatCurrency(this.totalTax)} &#10;</text>\n`+
      `<text>  &#10;</text>\n`+
      `<text>${this.sliceString("AMOUNT",10)} ${this.sliceString("TAX%",6)} TAX  &#10;</text>\n`;
      this.taxes.forEach((tax,i) => {
        xml+=`<text>${this.sliceString(this.formatCurrency(tax.price),10)} ${this.sliceString(tax.vat,6)} ${this.formatCurrency(tax.vatAmount)} &#10;</text>\n`;
      });
      xml+=
      `<text>  &#10;</text>\n`+
      `<text>${this.order.transactionData.result[0].customerReceipt.replace('', "")} &#10;</text>\n`;
      const settings=JSON.parse(localStorage.getItem('settings'));
      if(settings.printFooter){
        xml+=`<text>\n\n${settings.printFooter}</text>\n`;
      }
      xml+=
      `<feed line="2"/>\n`+
      `<feed line="3"/>\n`+
      `<cut type="feed"/>\n`+
      `</epos-print>`;
      return xml;
  }

  printReceipt(data){
    this.order=data.order;
    this.productOrders=this.order.productOrders;
    this.organizationData=data.organization;
    
    let taxes=[];
    this.productOrders.forEach(po => {
      const vatAmount=po.price-(po.price*100)/(100+po.vat);
      if(taxes[po.vat]){
        taxes[po.vat].vat=po.vat;
        taxes[po.vat].price+=po.price*po.quantity;
        taxes[po.vat].vatAmount+=vatAmount*po.quantity;
      }else{
        taxes[po.vat]={
          vat:po.vat,
          price:po.price*po.quantity,
          vatAmount:vatAmount*po.quantity
        };
      }
      
    });
    this.taxes=[];
    this.totalTax=0;
    taxes.forEach(element => {
      const tax=parseFloat(element.vatAmount.toFixed(2));
      this.totalTax+= tax;
      
      if(element){
        this.taxes.push(element);
      }
    });

    this._scriptService.load('epos').then(data => {
      this.setPrinterConfig();
      let receipt=this.prepareXMLReceipt(data);

      //Set the end point address
      var address = `http://${this.printerIP}/cgi-bin/epos/service.cgi?devid=${this.printerDeviceId}&timeout=10000`;
      //Create an ePOS-Print object
      var epos = new epson.ePOSPrint(address);
      //Send the print document
      epos.send(receipt);

    }).catch(error => console.log(error));

  }

  sliceString(word,digit){
    let string="                         ";// string with 25 characters
    let output=word+string;
    return output.substring(0,digit);
  }
}
