<div mat-dialog-title>
  <button mat-icon-button mat-dialog-close class="btn-close ml-auto">
    <mat-icon svgIcon="icon-close"></mat-icon>
  </button>
</div>
<mat-dialog-content class="text-center">
  <h4 *ngIf="data.title" class="text-primary" fxLayout="column" fxLayoutAlign="center center">
    {{data.title}}
  </h4>
  <p class="message">{{data.messages}}</p>
</mat-dialog-content>
