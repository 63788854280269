import { Injectable } from "@angular/core";
import { Cart } from "../_models/cart";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class KioskService {
  activeCategory = new BehaviorSubject<string>("");
  selectedProduct = new BehaviorSubject<any>(null);
  cartProducts = new BehaviorSubject<any>(null);
  selectedlanguage = new BehaviorSubject<string>("");
  startIdle = new BehaviorSubject<boolean>(false);
  public mealCategoryIds = new BehaviorSubject<string[]>([]);
  constructor() {}

  // returns a well prepared product order to be added to cart
  prepareProductOrder(quantity, product, selectedSize, selectedHotness) {
    let productOrder = {
      id: product.id,
      category: product.category,
      name: product.name,
      price: product.price,
      vat: product.vatAmount,
      image: product.image,
      description: product.description,
      allergyInfoString: product.allergyInfoString,
      type: product.typeConfigChoice?.displayName,
      sizes: [],
      instructions: [],
      ingredients: [],
      quantity: quantity,
      ageRestriction: product.ageRestriction,
    };
    // prepare size
    if (selectedSize && selectedSize.id) {
      let extras = [];
      if (selectedSize.extras) {
        selectedSize.extras.forEach((extra) => {
          if (extra.selected) {
            extras.push({
              id: extra.id,
              title: extra.title,
              price: extra.price,
              vat: extra.vatAmount,
              quantity: extra.quantity || 1,
            });
          }
        });
      }
      let size = {
        id: selectedSize.id,
        size: selectedSize.size,
        price: selectedSize.price,
        vat: selectedSize.vatAmount,
        extras: extras,
      };
      productOrder.sizes.push(size);
    }
    // prepare instructions
    if (product.instructions) {
      let instructions = [];
      product.instructions.forEach((instruction) => {
        if (instruction.selected) {
          instructions.push({
            id: instruction.id,
            instruction: instruction.instruction,
          });
        }
      });
      productOrder.instructions = instructions;
      //set hotness as instruction
      if (selectedHotness) {
        let hotness = {
          id: null,
          instruction: null,
          hotness: selectedHotness,
        };
        productOrder.instructions.push(hotness);
      }
    }
    // prepare ingredients
    if (product.ingredients) {
      let ingredients = [];
      product.ingredients.forEach((ingredient) => {
        if (ingredient.selected) {
          ingredients.push({
            id: ingredient.id,
            name: ingredient.name,
          });
        }
      });
      productOrder.ingredients = ingredients;
    }

    return productOrder;
  }

  calculateProductPrice(product, selectedSize, extras) {
    // console.log(product.price,selectedSize.price, extras,selectedSize);

    let price = product.price;
    if (selectedSize.price) {
      price = selectedSize.price;
    }
    if (selectedSize.extras) {
      selectedSize.extras.forEach((extra) => {
        if (extra.selected) {
          price += extra.price * (extra.quantity | 1);
        }
      });
    }
    //extras or additional toppings added
    if (extras) {
      extras.forEach((ext) => {
        const extraPrice = ext.price * (ext.quantity || 1);
        price += extraPrice;
      });
    }

    return price;
  }

  getCartFromProducts(productOrders, discount?: number) {
    let cart = new Cart();
    cart.productOrders = productOrders;
    let subTotal = 0;
    let vatAmount = 0;
    cart.productOrders.forEach((productOrder) => {
      let price = productOrder.price;
      let vat = productOrder.vat * productOrder.quantity;
      if (productOrder.sizes && Array.isArray(productOrder.sizes)) {
        productOrder.sizes.forEach((size) => {
          price = size.price ? size.price : price; // set price of size
          vat = size.vat * productOrder.quantity;
          if (size.extras && Array.isArray(size.extras)) {
            size.extras.forEach((extra) => {
              // add price of each extra
              price += extra.price * (extra.quantity || 1);
              vat += extra.vat * (extra.quantity * productOrder.quantity || 1);
            });
          }
        });
      }
      productOrder.totalPrice = price * productOrder.quantity;
      subTotal += productOrder.totalPrice;
      vatAmount += vat; //sum up vat amount calculated from server
    });
    cart.totalWithoutVat = subTotal;
    cart.vat = vatAmount;
    if (discount) {
      cart.discount = subTotal * (discount / 100);
      cart.total = subTotal - cart.discount;
    } else cart.total = Number(subTotal.toFixed(2)); // assuming vat are included in price
    return cart;
  }

  addToCart(existingProductOrders, productOrder, qunatityIncrement?: boolean) {
    let matched = false;
    existingProductOrders.forEach((po) => {
      // if same product matched
      if (po.id == productOrder.id) {
        let extraMathced = false;
        let instuctionMatched = false;
        if (po.sizes.length > 0) {
          if (po.sizes[0].id == productOrder.sizes[0].id) {
            //extras logic
            let newExtras = productOrder.sizes[0].extras.map((e) => ({ id: e.id, quantity: e.quantity }));
            let oldextras = po.sizes[0].extras.map((e) => ({ id: e.id, quantity: e.quantity }));
            extraMathced = JSON.stringify(newExtras) == JSON.stringify(oldextras);
          }
        } else {
          extraMathced = true;
        }
        let newInstructions = productOrder.instructions.map((e) => e.instruction);
        let oldInstructions = po.instructions.map((e) => e.instruction);

        instuctionMatched = JSON.stringify(newInstructions) == JSON.stringify(oldInstructions);

        let newHotness = productOrder.instructions.map((e) => e.hotness);
        let oldHotness = po.instructions.map((e) => e.hotness);
        let hotnessMatched = JSON.stringify(newHotness) == JSON.stringify(oldHotness);
        if (instuctionMatched && extraMathced && hotnessMatched) {
          matched = true;
          if (qunatityIncrement) {
            po.quantity = productOrder.quantity;
          } else {
            po.quantity += productOrder.quantity;
          }
        }
      }
    });
    if (!matched) {
      existingProductOrders = [...existingProductOrders, productOrder];
    }
    this.cartProducts.next(existingProductOrders);
    return existingProductOrders;
  }

  set setCartProducts(products: any) {
    this.cartProducts.next(products);
  }

  checkAgeRestriction(productOrders: Array<any>) {
    let hasAgeRestriction = false;
    productOrders.forEach((productOrder) => {
      if (productOrder.ageRestriction) {
        hasAgeRestriction = true;
      }
    });
    return hasAgeRestriction;
  }

  set setActiveCategory(id: string) {
    this.activeCategory.next(id);
  }

  set setSelectedProduct(product: any) {
    this.selectedProduct.next(product);
  }

  get getActiveCategory() {
    return this.activeCategory.asObservable();
  }

  get getSelectedProduct() {
    return this.selectedProduct.asObservable();
  }

  set setMealCategoryIds(ids: string[]) {
    this.mealCategoryIds.next(ids);
  }

  get getMealCategoryIds() {
    return this.mealCategoryIds.asObservable();
  }

  set setSelectedLanguage(lang: string) {
    this.selectedlanguage.next(lang);
  }

  get getSelectedLanguage() {
    return this.selectedlanguage.asObservable();
  }

  set setCartProduct(products) {
    this.cartProducts.next(products);
  }

  set setIdle(value: boolean) {
    this.startIdle.next(value);
  }

  get getIdle() {
    return this.startIdle.asObservable();
  }
}
