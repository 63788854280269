import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { LayoutModule } from './layout/layout.module';
import { MatDialogModule } from '@angular/material/dialog';
import { PasswordPromptComponent } from './pages/password-prompt/password-prompt.component';
import { AuthGuard } from './core/auth';

@NgModule({
  declarations: [
    AppComponent, PasswordPromptComponent,
  ],
  imports: [
    BrowserModule,
    MatDialogModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    SharedModule,
    TranslateModule.forRoot(),
  ],
  entryComponents: [PasswordPromptComponent],
  providers: [AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
