<!-- 
<h5 mat-dialog-title>{{data.title}}</h5>
<p mat-dialog-content>{{viewLoading ? data.waitDesciption : data.description}}</p>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancel</button>&nbsp;
  <button mat-button (click)="onYesClick()" color="primary" cdkFocusInitial
    [disabled]="viewLoading">{{data.buttonLabel}}</button>
</div> -->


<mat-progress-bar mode="indeterminate" *ngIf="viewLoading"></mat-progress-bar>
<mat-dialog-content class="row">
  <div class="cols" fxFlex="15%">
    <mat-icon svgIcon="icon-warn" style="width: 4rem; height: 4rem;"></mat-icon>
  </div>
  <div class="cols" fxFlex="85%">
    <div class="default-modal--alert-action__title mb-1" fxLayoutAlign="space-between start">
      <h5 class="m-0">{{data.title}}</h5>
      <button mat-icon-button mat-dialog-close class="btn-close ml-auto">
        <mat-icon svgIcon="icon-close"></mat-icon>
      </button>
    </div>
    <p>{{viewLoading ? data.waitDesciption : data.description}}</p>
  </div>
</mat-dialog-content>

<div mat-dialog-actions style="padding: 1.8rem 0 2.4rem;" fxLayoutAlign="end center">
  <button mat-button class="btn-default btn-default--outlined" (click)="onNoClick()">Cancel</button>
  <button mat-button class="btn-default btn-default--success" (click)="onYesClick()" cdkFocusInitial
    [disabled]="viewLoading">{{data.buttonLabel}}</button>
</div>
