export const icons = [
  {
    name: "icon-close",
    url: "assets/images/icons/icon-close.svg",
  },
  {
    name: "icon-back",
    url: "assets/images/icons/back-icon.svg",
  },
  {
    name: "icon-cart",
    url: "assets/images/icons/food-tray.svg",
  },
  {
    name: "icon-filter",
    url: "assets/images/icons/icon-filter.svg",
  },
  {
    name: "icon-completed",
    url: "assets/images/icons/completed.svg",
  },
  {
    name: "icon-1-column",
    url: "assets/images/icons/rectangle1.svg",
  },
  {
    name: "icon-2-column",
    url: "assets/images/icons/rectangle2.svg",
  },
  {
    name: "icon-3-column",
    url: "assets/images/icons/rectangle3.svg",
  },

  // Theme V2 Icons
  {
    name: "icon-burger",
    url: "assets/images/v2/icons/icon-burger.svg",
  },
  {
    name: "icon-pack-burger",
    url: "assets/images/v2/icons/icon-pack-burger.svg",
  },
  {
    name: "icon-chevron-down",
    url: "assets/images/v2/icons/icon-chevron-down.svg",
  },
  {
    name: "icon-check",
    url: "assets/images/v2/icons/icon-check.svg",
  },
  {
    name: "icon-add",
    url: "assets/images/v2/icons/icon-add.svg",
  },
  {
    name: "icon-minus",
    url: "assets/images/v2/icons/icon-minus.svg",
  },
  {
    name: "icon-paper",
    url: "assets/images/v2/icons/icon-paper.svg",
  },
  {
    name: "icon-mobile",
    url: "assets/images/v2/icons/icon-mobile.svg",
  },
  //Theme 3
  {
    name: "icon-cheveron-left",
    url: "assets/template3/images/icons/icon-chevron-left.svg",
  },
  {
    name: "icon-cheveron-right",
    url: "assets/template3/images/icons/icon-chevron-right.svg",
  },
  {
    name: "icon-eatin",
    url: "assets/template3/images/icons/icon-eatin.svg",
  },
  {
    name: "icon-takeout",
    url: "assets/template3/images/icons/icon-takeout.svg",
  },
  {
    name: "icon-burgers",
    url: "assets/template3/images/icons/icon-burgers.svg",
  },
  {
    name: "icon-drinks",
    url: "assets/template3/images/icons/icon-drinks.svg",
  },
  {
    name: "icon-sides",
    url: "assets/template3/images/icons/icon-sides.svg",
  },
  {
    name: "icon-salad",
    url: "assets/template3/images/icons/icon-salad.svg",
  },
  {
    name: "icon-meal",
    url: "assets/template3/images/icons/icon-meal.svg",
  },
  {
    name: "icon-dips",
    url: "assets/template3/images/icons/icon-dips.svg",
  },
  {
    name: "icon-circle-success",
    url: "assets/template3/images/icons/icon-circle-success.svg",
  },
  {
    name: "icon-trash",
    url: "assets/template3/images/icons/icon-trash.svg",
  },
  {
    name: "icon-trash-o",
    url: "assets/template3/images/icons/icon-trash-0.svg",
  },
  {
    name: "icon-terminal",
    url: "assets/template3/images/icons/icon-teminal.svg",
  },
  {
    name: "icon-failed",
    url: "assets/template3/images/icons/icon-failed.svg",
  },
  {
    name: "icon-refresh",
    url: "assets/template3/images/icons/icon-refresh.svg",
  },
  {
    name: "icon-remove-circle",
    url: "assets/template3/images/icons/icon-remove-circle.svg",
  },
  {
    name: "icon-allergen-chili",
    url: "assets/template3/images/icons/icon-allergen-chili.svg",
  },
  {
    name: "icon-allergen-egg",
    url: "assets/template3/images/icons/icon-allergen-egg.svg",
  },
  {
    name: "icon-allergen-fish",
    url: "assets/template3/images/icons/icon-allergen-fish.svg",
  },
  {
    name: "icon-allergen-gluten",
    url: "assets/template3/images/icons/icon-allergen-gluten.svg",
  },
  {
    name: "icon-allergen-lactose",
    url: "assets/template3/images/icons/icon-allergen-lactose.svg",
  },
  {
    name: "icon-allergen-soy",
    url: "assets/template3/images/icons/icon-allergen-soy.svg",
  },
  {
    name: "icon-allergen-vegan",
    url: "assets/template3/images/icons/icon-allergen-vegan.svg",
  },
  {
    name: "icon-restriction",
    url: "assets/template3/images/icons/icon-restriction.svg",
  },
  {
    name: "icon-recommended",
    url: "assets/template3/images/icons/icon-recommended.svg",
  },
  {
    name: "icon-cancel-order",
    url: "assets/template3/images/icons/icon-cancel-order.svg",
  },
  {
    name: "icon-app",
    url: "assets/template3/images/icons/icon-app.svg",
  },
  {
    name: "icon-card",
    url: "assets/template3/images/icons/icon-card.svg",
  },
  {
    name: "icon-voucher",
    url: "assets/template3/images/icons/icon-voucher.svg",
  },
  {
    name: "icon-confirm-app",
    url: "assets/template3/images/icons/icon-confirm-app.svg",
  },
  {
    name: "icon-confirm-card",
    url: "assets/template3/images/icons/icon-confirm-card.svg",
  },
  {
    name: "icon-confirm-voucher",
    url: "assets/template3/images/icons/icon-confirm-voucher.svg",
  },
  {
    name: "icon-press",
    url: "assets/template4/images/icons/icon-press.svg",
  },
  {
    name: "icon-refresh-outline",
    url: "assets/template4/images/icons/icon-refresh-outline.svg",
  },
  {
    name: "icon-clock",
    url: "assets/template4/images/icons/icon-clock.svg",
  },
  {
    name: "logo-samsung",
    url: "assets/template4/images/logo-samsung.svg",
  },
  {
    name: "logo-kiosk",
    url: "assets/template4/images/logo-kiosk.svg",
  },
  {
    name: "logo-odyssey",
    url: "assets/template4/images/logo-odyssey.svg",
  },
  {
    name: "icon-thumb",
    url: "assets/images/icon-thumb.svg",
  },
  {
    name: "icon-tick",
    url: "assets/images/icon-tick.svg",
  },
  {
    name: "icon-paymentFailed",
    url: "assets/images/paymentFailed.svg",
  },
  {
    name: "icon-erase",
    url: "assets/images/icons/icon-erase.svg",
  },
  {
    name: "applied-icon-coupon",
    url: "assets/template5/svgs/applied-icon.svg",
  },
];
